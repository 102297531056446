<template>
	<div class=''>
		<!-- 查询条件 -->
		<NavbarVue padding="5px 60px">
			<el-form :inline="true" :model="searchParam" class="demo-form-inline" size="medium">
				<el-form-item>
					<el-input v-model="searchParam.kyc_no" placeholder="KYC ID" />
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchParam.client_code" placeholder="Client Name" />
				</el-form-item>
				<el-form-item  v-if="$store.state.User.Rep.rep_category !== 'Tier1'">
					<el-input v-model="searchParam.creator" placeholder="Creator" />
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchParam.status" placeholder="Status">
						<el-option v-for="item in statusOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchParam.due_time" placeholder="Expire">
						<el-option v-for="item in expireOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-button type="info" @click="getTableData('1')" size="medium" class="grewBtn">Search</el-button>
			</el-form>
		</NavbarVue>
		<!-- KYC -->
		<div class="content">
			<span style="font-size: 20px;color: #909399;">Found {{pages.total}} KYC(s)</span>
			<el-table :data="tableData" @row-click="rowClick" style="width: 100%;overflow-y: scroll;" :height="tableHeight" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}" stripe>
				<el-table-column prop="kyc_no" label="KYC ID" width="250">
				</el-table-column>
				<el-table-column prop="" label="Client Name">
					<template slot-scope="scope">
						<p>{{scope.row.client_name_a}}</p>
						<p>{{scope.row.client_name_b}}</p>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="recording" label="Video Recording" width="260">
					<template slot-scope="scope">
						<img src="../../assets/img/cka/CKA.passed.png" alt="" v-if="scope.row.cka_a.indexOf('1') > -1 || scope.row.cka_a.indexOf('2') > -1 || ( scope.row.cka_b && (scope.row.cka_b.indexOf('1') > -1 || scope.row.cka_b.indexOf('2') > -1))" width="50px" class="mt10px" style="float: left;">
						<SwitchBoxVue :status.sync="scope.row.is_recording" @valueChange="valueChange" :row="scope.row" :returnType="false" width="120px" style="margin-left: 70px;"></SwitchBoxVue>
					</template>
				</el-table-column> -->
				<el-table-column prop="creat_time" label="Create Date" width="200" >
					<template slot-scope="scope">
						{{scope.row.creat_time | dateFmt('MM/DD/YYYY')}}
					</template>
				</el-table-column>
				<el-table-column prop="creater" label="Creator" v-if="$store.state.User.Rep.rep_category !== 'Tier1'" />
				<el-table-column prop="status" label="Status" width="120">
					<template slot-scope="scope">
						<div class="statusCell w100px" :style="{'color': scope.row.color, 'background-color': scope.row.backgroundColor, 'border-color': scope.row.borderColor }">
							{{$dictStatus(scope.row.status)}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="PDF" width="80">
					<template slot-scope="scope" v-if="scope.row.kyc_attachment">
						<img src="../../assets/img/pdf.png" alt="" @click="handleImg(scope.row.kyc_attachment)">
					</template>
				</el-table-column>
				<el-table-column prop="address" label="Action" align="left" width="350">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="handleEdit(scope.row.kyc_no)" :disabled="!((scope.row.status === '10' || scope.row.status === '40') && ApiData.agent_code === scope.row.agent_code)">Edit</el-button>
						<el-button type="danger" size="mini" @click="handleDelete(scope.row.kyc_no)" :disabled="!((scope.row.status === '10' || scope.row.status === '5') && ApiData.agent_code === scope.row.agent_code)">Delete</el-button>
						<el-button type="info" size="mini" @click="handleUnlock(scope.row)" :disabled="!((scope.row.status === '15') && ApiData.agent_code === scope.row.agent_code)">Unlock</el-button>
						<el-button type="success" size="mini" @click="handleSign(scope.row.kyc_no)" :disabled="!((scope.row.status === '15') && ApiData.agent_code === scope.row.agent_code)">Sign</el-button>
						<!-- <el-button type="success" size="mini" @click="handleVideoRecording(scope.row)" v-if="scope.row.video_status !== '0' && scope.row.is_recording === '0' && scope.row.kyc_link && scope.row.status === '15' && ApiData.agent_code === scope.row.agent_code">Video Recording</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<PaginationVue :pages="pages" listKey="getTableData" pageKey="pages" @SetPagination="$SetPagination">
			</PaginationVue>
		</div>
		<sign-dialog :kycPDFLink="kycPDFLink" :Dialog="Dialog"></sign-dialog>
	</div>
</template>

<script>

import NavbarVue from "../../components/Navbar.vue";
import SwitchBoxVue from "../../components/switchBox.vue";
import PaginationVue from "../../components/Pagination.vue";
import { kycList } from '@/api/KYC/index.js'
import { plicyView } from '@/api/Search/index.js'
import { editKyc, miSignRequest, deleteKyc, updateLockStatus, videoRecordingStatus } from '@/api/KYC'
import { GET_username } from '../../utils/localStorage';
import SignDialog from '../../views/KYC/components/signDialog.vue';
import _ from 'lodash';
export default {
	components: { NavbarVue, SwitchBoxVue, PaginationVue, SignDialog },
	data () {
		return {
			tableHeight: '',
			activeName: 'KYC',
			statusOptions: [
				{
					value: '',
					label: 'All'
				}, {
					value: '5',
					label: 'Invalid'
				}, {
					value: '10',
					label: 'Draft'
				}, {
					value: '20',
					label: 'Pending'
				}, {
					value: '30',
					label: 'Approved'
				}, {
					value: '40',
					label: 'Rejected'
				}, {
					value: '50',
					label: 'Expired'
				}, {
					value: '15',
					label: 'Locked'
				}
			],
			expireOptions: global.expireOptions,
			tableData: [],
			searchParam: {
				'kyc_no': '',
				'client_code': '',
				'status': '',
				'creator': '',
				'due_time': '',
				'create_time': '',
				'agent_code': GET_username(),
				'search_code': ''
			},
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
			Dialog: {
				visible: false,
				status: "Add"
			},
			kycPDFLink: '',
			kyc_no: ''
		};
	},
	computed: {
		// 公共参数设置
		ApiData () {
			return { page_number: String(this.pages.pageNum), page_size: String(this.pages.pageSize), ...this.searchParam }
		},
	},
	watch: {
		'Dialog.visible' (val) {
			this.getTableData('1')
		}
	},
	methods: {
		// 动态设置表格高度
		getHeight () {
			this.tableHeight = window.innerHeight - 270
		},
		// 点击图片预览pdf
		handleImg (pdf) {
			localStorage.setItem('PDFUrl', pdf)
			const { href } = this.$router.resolve({
				path: '/Preview'
			});
			window.open(href, '_blank');
		},
		// 表格操作-详情
		debounceEdit: _.debounce(function() {
			editKyc({ kyc_no: this.kyc_no }).then(res => {
				// 1.存储kyc数据 2.存储当前页数 3.跳转页面
				this.$store.commit('SET_KYCCLIENT', 0)
				this.$store.commit('SET_KYC_ALL_DATA', { kyc: res.content })
				this.$store.commit('SET_KYCPAGEITEM', 0)
				this.$store.commit('SET_PROTECTION_NEEDS', [])
				this.$router.push('/KYC&FNA')
			})
		}, 2000, {
				'leading': true, //在延迟开始前立即调用事件
				'trailing': false, //在延时结束后不调用,保证事件只被触发一次
		}),
		handleEdit (kyc_no) {
			this.kyc_no = kyc_no
			this.debounceEdit()
		},
		// 表格操作-删除
		handleDelete (kyc_no) {
			this.$confirm('confirm to delete?', 'Alert', {
				confirmButtonText: 'CONFIRM',
				cancelButtonText: 'CANCEL',
				type: "info",
			}).then(() => {
				// 删除逻辑
				deleteKyc({ kyc_no }).then(() => {
					this.getTableData()
				})
			}).catch(() => { })
		},
		// 表格操作-锁定
		handleUnlock (row) {
			updateLockStatus({
				'kyc_no': row.kyc_no,
				'submit_audit_status': '10',
				'agent_no': row.agent_code
			}).then(res => {
				this.getTableData()
				this.handleEdit(res.content.kyc_no)
			})
		},
		// 表格操作-sign
		async handleSign (kyc_no) {
			// 打开弹框 展示二维码、点击复制、点击跳转
			let res = await miSignRequest({ kyc_no })
			if (res.content.redirectURL) {
				this.Dialog.visible = true
				this.kycPDFLink = res.content.redirectURL
			}
		},
		// 表格操作-video 
		handleVideoRecording () {
			// videoRecordingStatus()
		},
		// 表格操作-行点击事件
		rowClick (row, column) {
			if (column && (column.label == 'Action' || column.label == 'PDF')) {
				return;
			} else {
				editKyc({ kyc_no: row.kyc_no }).then(res => {
					// 1.存储kyc数据 2.跳转页面
					this.$store.commit('SET_KYC_ALL_DATA', { kyc: res.content })
					// 获取名称
					let clientNames = []
					res.content.client.map(res => { clientNames.push(res.personal_information.surname) })
					this.$store.commit('SET_CLIENTNAME', clientNames)
					this.$router.push('/kyc')
				})
			}
		},
		// 获取表格数据
		async getTableData (val) {
			if (val) this.ApiData.page_number = val
			let res = await kycList(this.ApiData)
			this.pages.total = res.content.count
			this.tableData = res.content.kyc_list
			this.tableData.map(data => {
				if (data.status && data.status !== '15') {
					data.color = global.config[data.status].statusColor
					data.backgroundColor = global.config[data.status].statusBgColor
					data.borderColor = global.config[data.status].statusColor
				}
			})
		},
		// 表格yes no切换  e用来判断是否是手动点击
		valueChange (row, e) {
			if (e) {
				plicyView({ is_recording: row.is_recording, kyc_no: row.kyc_no }).then()
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		window.addEventListener('resize', this.getHeight)
		this.getHeight()
		if (this.$store.state.User.PageItem === 2) this.getTableData()
},
mounted() { },
destroyed() { window.removeEventListener('resize', this.getHeight) }
}
</script>
<style lang='css' scoped>
.content {
	padding: 15px 60px 10px 60px;
}
/* form表单样式 */
.el-form {
	display: flex;
}
.el-form-item {
	margin-right: 25px;
}
.el-form >>> .el-input__inner {
	height: 50px;
}
.statusCell {
	border: 1px solid rgb(12, 12, 12);
}
</style>
<template>
	<div class='Wrapper'>
		<!-- 顶部tab页 -->
		<el-tabs v-model="activeName" @tab-click="handleClick" :stretch=true>
			<el-tab-pane label="KYC" name="KYC"></el-tab-pane>
			<el-tab-pane label="Submission" name="Submission"></el-tab-pane>
			<el-tab-pane label="Policy" name="Policy"></el-tab-pane>
		</el-tabs>
		<!-- 各tab页对应的详情页面 -->
		<component :is="list[item]"></component>
	</div>
</template>

<script>
import KYC from './searchComponents/KYCpage.vue';
import Submission from "./searchComponents/Submission.vue";
import Policy from "./searchComponents/Policypage";

export default {
	components: { KYC, Submission, Policy },
	data() {
		return {
			list: [KYC, Submission, Policy],
			item: 0,
			activeName: 'KYC'
		};
	},
	computed: {},
	watch: {},
	methods: {
		// tab点击事件
		handleClick(tab, event) {
			this.item = parseInt(tab.index)
		}
	},
}
</script>
<style lang='css'>
.el-tabs__header {
	margin: 0;
}
.el-tabs__nav {
	height: 60px;
}
.el-tabs__item {
	line-height: 60px;
}
/* tabs选中颜色设置 */
.el-tabs__active-bar {
  height: 5px;
  background-color: #FDB551;
}
.el-tabs__item:hover, .el-tabs__item.is-active {
	color: #FDB551;
    font-weight: 600;
}
</style>
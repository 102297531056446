<template>
	<div class='Wrapper h100'>
		<!-- barner -->
		<div class="Banner">
			<h2>Welcome</h2>
			<img src="../assets/img/banner_new.png" alt="">
		</div>
		<!-- 区块盒子 -->
		<div class="block">
			<div class="displayFlex flexSpace Flex">
				<div class="OriginProduct" :style="UserLevel ? '' : {width: '45%'}">
					<img src="../assets/img/KYC2x.png">
					<span @click="jumpPage('1')">Start a new KYC</span>
				</div>
				<!-- Pending Tasks  T2,T3级别用户展示 -->
				<div class="OriginProduct" v-show="UserLevel">
					<img src="../assets/img/list2x.png">
					<span @click="jumpPage('2')">Pending Tasks</span>
					<el-badge :value="badgeValue"></el-badge>
				</div>
				<div class="OriginProduct" :style="UserLevel ? '' : {width: '45%'}">
					<img src="../assets/img/portal2x.png">
					<span @click="jumpPage('3')">Adviser Portal</span>
				</div>
			</div>
			<div class="displayFlex flexSpace Flex">
				<div class="OriginLink" style="padding: 0;position: relative;color: #F36A10;" @click="openVideo">
					<img src="../assets/img/home-playCircle.png" style="position: absolute;left: 30px;bottom: 80px;" alt="">
					<p style="position: absolute;left: 10px;bottom: 10px;font-size: 34px">Infinitium Introduction Video</p>
					<img :src="videoImg" alt="" style="width: 100%;height: 100%;border-radius: 5px;" @error="setDefaultImage">
				</div>
				<div class="OriginLink">
					<img class="mb10px" src="../assets/img/logo.infinitum.light2x.png">
					<p class="financial ml100px">Financial Advisory</p>
					<p class="Username">{{agentForm.rep_name}}</p>
					<!-- 表单 -->
					<el-form :model="agentForm" label-position="right" label-width="100px" class="mt10px">
						<el-form-item label="RNF NO：">{{agentForm.rnf_code}}</el-form-item>
						<el-form-item label="Mobile：">{{agentForm.mobile}}</el-form-item>
						<el-form-item label="Email：">{{agentForm.email}}</el-form-item>
						<el-form-item label="Address：">{{agentForm.company_address}}</el-form-item>
					</el-form>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { selectAgentInfo, getToDoList } from 'api/Home'
import { productCommonInfoQuery } from 'api/Product'
import { GET_username, SET_areaCodeList } from '../utils/localStorage';
import { getAreaCode } from '@/api/KYC/index.js'
export default {
	components: {},
	data () {
		return {
			videoImg: null,
			UserLevel: false,
			agentForm: {},
			badgeValue: 0
		};
	},
	computed: {},
	watch: {},
	methods: {
		jumpPage (val) {
			if (val === '1') {
				this.$router.push('/NoticeToClient')
			} else if (val === '2') {
				this.$router.push('/PendingTasks')
			} else if (val === '3') {
				if (process.env.VUE_APP_TITLE == 'dev') window.open('http://82.156.198.177:9000/portal/login', '_blank')
				if (process.env.VUE_APP_TITLE == 'uat') window.open('https://fab-uat.infinitumfa.com/portal/login', '_blank')
				if (process.env.VUE_APP_TITLE == 'prod') window.open('https://fab.infinitumfa.com/portal/login', '_blank')
			}
		},
		openVideo () {
			window.open(localStorage.getItem('video_address'), '_blank')
		},
		setDefaultImage (e) {
			e.target.src = require('../assets/img/home-green.png')
		},
		async getCardInfo () {
			if (!GET_username()) this.$router.push('/login')
			let res = await selectAgentInfo({ infinitum_rep_code: GET_username() })
			this.agentForm = { ...res.content }
			this.UserLevel = this.agentForm.rep_category !== 'Tier1'
			let product = await productCommonInfoQuery({ advisor_code: GET_username() })
			this.agentForm.authorized_product_line_list = product.content.product_line
			this.agentForm.provider_rep_code_list = product.content.provider
			this.$store.commit('SET_REP', this.agentForm)
			let to_do_list = await getToDoList({
				agent_code: GET_username(),
				rep_category: this.$store.state.User.Rep.rep_category,
				kyc_no: '',
				client_code: '',
				type: '1',
				page_number: '1',
				page_size: '9999',
			})
			this.badgeValue = to_do_list.content.count
		},
		// 所有国家地区电话区号
		getAreaCode () {
			getAreaCode({}).then(res => {
				if (res) SET_areaCodeList(JSON.stringify(res.content.area_code_list))
			})
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		if (this.$store.state.User.PageItem === 0) {
			this.videoImg = localStorage.getItem('picture_attachment')
			this.getCardInfo()
			// 获取国家、地区
			this.getAreaCode()
		}
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
}
</script>
<style lang='css' scoped>
* {
	user-select: none;
}
.Wrapper {
	overflow: scroll;
}
h2 {
	position: absolute;
	top: 100px;
	left: 150px;
	font-size: 50px;
	color: #fff;
}
.Banner {
	position: absolute;
	width: 100%;
	height: 350px;
	background: #11131c;
}
.Banner img {
	position: absolute;
	right: 0;
}

.financial {
	font-size: 22px;
}
.Username {
	font-size: 40px;
	margin-bottom: 24px;
}
p {
	margin-bottom: 10px;
	font-size: 18px;
}
.block {
	width: 90%;
	height: 560px;
	margin: 220px auto 50px auto;
	position: relative;
}
.Flex:nth-of-type(1) {
	margin-bottom: 40px;
}
.OriginProduct {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	width: 30%;
	height: 180px;
	-moz-box-shadow: 0px 0px 6px #33333366;
	-webkit-box-shadow: 0px 0px 6px #33333366;
	box-shadow: 0px 0px 6px #33333366;
}
.OriginProduct > img {
	width: 60px;
}
.OriginProduct > span {
	font-size: 30px;
	cursor: pointer;
	margin-left: 35px;
}
.OriginLink {
	padding: 20px;
	width: 45%;
	height: 350px;
}
.OriginProduct,
.OriginLink {
	box-sizing: border-box;
	background: #fff;
	border-radius: 5px;
	border: 1px solid #bab8b8;
}
.el-form >>> .el-form-item__label,
.el-form >>> .el-form-item__content {
	line-height: 30px;
	font-size: 16px;
	color: #000;
}
.el-form >>> .el-form-item {
	margin-bottom: 0px !important;
}
.el-badge >>> .el-badge__content {
	background-color: #f74323;
	border-radius: 15px;
	font-size: 20px;
	padding: 4px 8px;
}
</style>
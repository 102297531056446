<template>
	<div class=''>
		<!-- 查询条件 -->
		<NavbarVue padding="5px 60px">
			<el-form :inline="true" :model="searchParam" class="demo-form-inline" size="medium">
				<el-form-item>
					<el-input v-model="searchParam.application_no" placeholder="KYC ID" />
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchParam.client_code" placeholder="Client Name" />
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchParam.status" placeholder="Status">
						<el-option v-for="item in statusOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-button type="info" @click="getTableData('1')" size="medium" class="grewBtn">Search</el-button>
			</el-form>
		</NavbarVue>
		<!-- Submission -->
		<div class="content">
			<span style="font-size: 20px;color: #909399;">Found {{pages.total}} Submission(s)</span>
			<el-table :data="tableData" style="width: 100%" @row-click="rowClick" :height="tableHeight" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}" stripe empty-text="No Data">
				<el-table-column prop="application_no" label="Application ID">
				</el-table-column>
				<el-table-column prop="kyc_no" label="KYC ID" width="250">
				</el-table-column>
				<el-table-column prop="client_name" label="Client Name">
				</el-table-column>
				<el-table-column prop="provider_name" label="Provider">
				</el-table-column>
				<el-table-column prop="product_name" label="Product">
				</el-table-column>
				<el-table-column prop="create_date" label="Create Date">
					<template slot-scope="scope">
						{{scope.row.create_date | dateFmt('MM/DD/YYYY')}}
					</template>
				</el-table-column>
				<el-table-column prop="statusName" label="Status">
				</el-table-column>
			</el-table>
			<PaginationVue :pages="pages" listKey="getTableData" pageKey="pages" @SetPagination="$SetPagination">
			</PaginationVue>
		</div>
	</div>
</template>

<script>

import NavbarVue from "../../components/Navbar.vue";
import PaginationVue from "../../components/Pagination.vue";
import { submissionList, editSubmission } from '@/api/KYC/index.js'
import { GET_username } from '../../utils/localStorage';
export default {
	components: { NavbarVue, PaginationVue },
	data () {
		return {
			tableHeight: '',
			activeName: 'KYC',
			statusOptions: [
				{
					value: '',
					label: 'All'
				}, {
					value: '0',
					label: 'Submission'
				}, {
					value: '1',
					label: 'lodged'
				}
			],
			tableData: [],
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
			searchParam: {
				agent_no: GET_username(),
				application_no: '',
				client_code: '',
				page_number: "1",
				page_size: "10",
				search_code: '',
				status: ''
			}
		};
	},
	computed: {},
	watch: {},
	methods: {
		// 动态设置表格高度
		getHeight () {
			this.tableHeight = window.innerHeight - 270
		},
		// 表格操作-行点击事件
		rowClick (row) {
			if (row.status === '0') {
				this.$confirm('No details to be shown yet', 'Alert', {
					showConfirmButton: false,
					cancelButtonText: 'OK',
					type: "info",
				}).then(() => { }).catch(() => { })
			} else {
				editSubmission({
					applicationReferenceNumber: row.application_no,
					productLine: row.product_line_code
				}).then(res => {
					// 1.存储submission数据 2.跳转页面
					this.$store.commit('SET_SUBMISSION_DETAIL', res.content)
					this.$router.push('/submission?header=Submission')
				})
			}
		},
		// 获取表格数据
		getTableData (val) {
			this.searchParam.page_number = val ? val : String(this.pages.pageNum)
			this.searchParam.page_size = String(this.pages.pageSize)
			submissionList(this.searchParam).then(res => {
				this.pages.total = res.content.count
				this.tableData = res.content.submission_list
				this.tableData.map(list => {
					let date = list.create_date.split(' ')[0].split('-')
					list.create_date = date[2] + '/' + date[1] + '/' + date[0]
					list.statusName = this.statusOptions.filter(n => n.value === list.status)[0].label
				})
			})
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		window.addEventListener('resize', this.getHeight)
		this.getHeight()
		this.getTableData()
	},
	destroyed () { window.removeEventListener('resize', this.getHeight) }
}
</script>
<style lang='css' scoped>
.content {
	padding: 15px 60px 10px 60px;
}
.el-form >>> .el-input__inner {
	height: 50px;
}
.el-form >>> .el-input__inner {
	height: 50px;
}
/* form表单样式 */
.el-form {
	display: flex;
}
.el-form-item {
	margin-right: 25px;
}
</style>
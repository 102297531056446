<template>
	<div class='h100 displayFlex flexColumn'>
		<!-- 查询条件 -->
		<NavbarVue padding="5px 60px">
			<el-form :inline="true" :model="searchParam" class="demo-form-inline" size="medium">
				<el-form-item>
					<el-select v-model="searchParam.product_line_code" placeholder="All Product Lines">
						<el-option v-for="item in productLineOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchParam.product_type_code" placeholder="Product Type">
						<el-option v-for="item in productTypeOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchParam.provider_code" placeholder="Provider">
						<el-option v-for="(item, index) in providerOptions" :key="index" :value="item.value" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchParam.product_name" placeholder="Product Name">
					</el-input>
				</el-form-item>
				<el-button type="info" @click="getTableData('1')" size="medium" class="grewBtn">Search</el-button>
			</el-form>
		</NavbarVue>
		<!-- product内容 -->
		<div class="content" id="content">
			<span style="font-weight: 600;font-size: 22px;color: #5D5D5D;">{{pages.total}} Product(s)</span>
			<!-- 产品列表盒子 -->
			<div class="mt20px displayFlex flexWrap flexJCspaceBetween overFlow" :style="BoxHeight">
				<!-- 各个产品 -->
				<div class="box displayFlex flexColumn mt10px" v-for="(item, index) in boxContent" :key="index">
					<div class="box_title ellipsis" style="margin-top: 0;-webkit-line-clamp: 1;">{{item.title}}</div>
					<div class="box_text">
						<div class="IncomeStyle h40px" :title="item.provider" @click="Income(item.infinitum_product_code)">{{item.provider}}</div>
						<!-- <div class="ellipsis" v-ellipsis="{ 'line': 2, 'showTitle': true}">{{item.text}}</div> -->
						<div class="ellipsis1" :title="item.text">{{item.text}}</div>
					</div>
				</div>
			</div>
			<PaginationVue :pages="pages" listKey="getTableData" pageKey="pages" @SetPagination="$SetPagination">
			</PaginationVue>
		</div>
	</div>
</template>

<script>
import NavbarVue from "../components/Navbar.vue";
import PaginationVue from "../components/Pagination.vue";
import { productCommonInfoQuery, productListQuery } from '../api/Product'
import { GET_providerOption, GET_username, SET_providerOption } from '../utils/localStorage'
export default {
	components: { NavbarVue, PaginationVue },
	data () {
		return {
			BoxHeight: {
				height: '800px'
			},
			boxContent: [],
			searchParam: {
				advisor_code: '',
				client_code: '',
				due_time: '',
				page_number: '1',
				page_size: '10',
				product_line_code: '',
				product_name: '',
				product_type_code: '',
				provider_code: '',
				status: '',
			},
			productLineOptions: [
				{
					value: '',
					label: 'All'
				}
			],
			productTypeOptions: [
				{
					value: '',
					label: 'All'
				}
			],
			providerOptions: [
				{
					value: '',
					label: 'All'
				}
			],
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
		};
	},
	computed: {},
	watch: {},
	methods: {
		// 产品详情跳转
		Income (code) {
			this.$router.push('/productDetail?code=' + code)
			// 暂存product页面查询条件、当前页数等信息
			sessionStorage.setItem('ProductParam', JSON.stringify(this.searchParam))
		},
		// 获取产品线、类型、提供者
		async getCommonInfo () {
			let product_line = []
			if (GET_providerOption()) {
				this.providerOptions = JSON.parse(GET_providerOption())
				product_line = JSON.parse(localStorage.getItem('product_line'))
			} else {
				let res = await productCommonInfoQuery({ advisor_code: GET_username() })
				res.content.provider.map(provider => {
					this.providerOptions.push({ value: provider.provider_code, label: provider.provider_name })
				})
				product_line = res.content.product_line
				// 存储product_line
				localStorage.setItem('product_line', JSON.stringify(product_line))
				// 存储provider Options
				SET_providerOption(JSON.stringify(this.providerOptions))
			}
			product_line.map(line => {
				this.productLineOptions.push({ value: line.product_line_code, label: line.product_line_name })
				line.product_type.map(type => {
					this.productTypeOptions.push({ value: type.product_type_code, label: type.product_type_name })
				})
			})
			// 从product详情页返回时 还原之前的查询条件
			if (sessionStorage.getItem('ProductParam')) {
				this.searchParam = JSON.parse(sessionStorage.getItem('ProductParam'))
				this.pages.pageNum = parseInt(this.searchParam.page_number)
				this.pages.pageSize = parseInt(this.searchParam.page_size)
				// 使用完成后删除
				sessionStorage.removeItem('ProductParam')
			}
			// 查询数据
			this.getTableData()
		},
		getTableData (val) {
			this.searchParam.page_number = val ? val : String(this.pages.pageNum)
			this.searchParam.page_size = String(this.pages.pageSize)
			this.searchParam.advisor_code = GET_username()
			productListQuery(this.searchParam).then(res => {
				this.pages.total = parseInt(res.content.total)
				this.boxContent = []
				res.content.product_list.map(list => {
					// 判断图片是否存在
					let ImgObj = new Image() 
					ImgObj.src = list.provider_logo_url
					this.boxContent.push({
						text: list.product_description,
						title: list.product_name,
						infinitum_product_code: list.infinitum_product_code,
						provider: list.provider_short_name,
						provider_logo_url: list.provider_logo_url
					})
				})
			})
		},
		// 设置box高度
		getHeight () {
			this.BoxHeight = {
				height: (window.innerHeight - 250) + 'px'
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		if (this.$store.state.User.PageItem === 1) this.getCommonInfo()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.getHeight()
		window.addEventListener('resize', this.getHeight)
	},
	beforeCreate () { }, //生命周期 - 创建之前
	beforeMount () { }, //生命周期 - 挂载之前
	beforeUpdate () { }, //生命周期 - 更新之前
	updated () { }, //生命周期 - 更新之后
	beforeDestroy () { }, //生命周期 - 销毁之前
	destroyed () { window.removeEventListener('resize', this.getHeight) }, //生命周期 - 销毁完成
	activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
.box {
	align-items: flex-end;
	width: 45%;
}
.box_title {
	height: 28px;
	font-weight: 600;
	margin-right: auto;
	font-size: 26px;
	color: #5a5b5e;
}
.box_text {
	width: 95%;
	font: menu;
	font-size: 16px;
	color: #5d5d5d;
}
.ellipsis {
	margin-top: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}
.ellipsis1 {
	margin-top: 60px;
	overflow:hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	display: -webkit-box;
}
.IncomeStyle {
	width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
	text-align: center;
	border: 1px solid #409eff;
    color: #409eff;
    margin-top: 10px;
    border-radius: 3px;
    line-height: 40px;
    white-space: nowrap;
    float: right;
	padding: 0 10px;
	cursor: pointer;
}
.content {
	flex: 1;
	overflow-y: scroll;
	padding: 15px 60px 10px 60px;
}
.overFlow {
	overflow: scroll;
}

.el-form >>> .el-input__inner {
	height: 50px;
}
/* form表单样式 */
.el-form {
	display: flex;
}
.el-form-item {
	margin-right: 25px;
}
</style>
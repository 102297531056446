<template>
	<div class=''>
		<!-- 查询条件 -->
		<NavbarVue padding="5px 60px">
			<el-form :inline="true" :model="searchParam" class="demo-form-inline" size="medium">
				<el-form-item>
					<el-input v-model="searchParam.policyNumber" placeholder="Policy ID" />
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchParam.clientName" placeholder="Client Name" />
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchParam.status" placeholder="Status">
						<el-option v-for="item in statusOptions" :key="item.code" :value="item.code" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-date-picker v-model="searchParam.effectiveDate" type="date" placeholder="Select Date">
					</el-date-picker>
				</el-form-item>
				<el-button type="info" @click="getTableData('1')" size="medium" class="grewBtn">Search</el-button>
			</el-form>
		</NavbarVue>
		<!-- KYC -->
		<div class="content">
			<span style="font-size: 20px;color: #909399;">Found {{pages.total}} Policy(s)</span>
			<el-table :data="tableData" @row-click="handleRowClick" style="width: 100%" :height="tableHeight" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}" stripe empty-text="No Data">
				<el-table-column prop="policyId" label="Policy ID">
				</el-table-column>
				<el-table-column prop="productLineName" label="Product Line">
				</el-table-column>
				<el-table-column prop="clientName" label="Client Name">
				</el-table-column>
				<el-table-column prop="provider" label="Provider">
				</el-table-column>
				<el-table-column prop="product" label="Product">
				</el-table-column>
				<el-table-column prop="effectiveDate" label="Eeffective Date">
					<template slot-scope="scope">
						{{scope.row.effectiveDate | dateFmt('MM/DD/YYYY')}}
					</template>
				</el-table-column>
				<el-table-column prop="status" label="Status">
				</el-table-column>
			</el-table>
			<PaginationVue :pages="pages" listKey="getTableData" pageKey="pages" @SetPagination="$SetPagination">
			</PaginationVue>
		</div>
	</div>
</template>

<script>

import NavbarVue from "../../components/Navbar.vue";
import SwitchBoxVue from "../../components/switchBox.vue";
import PaginationVue from "../../components/Pagination.vue";
import { policyList, editPolicy } from '@/api/KYC/index.js'
import { GET_username } from "../../utils/localStorage";
export default {
	components: { NavbarVue, SwitchBoxVue, PaginationVue },
	data() {
		return {
			tableHeight: '',
			activeName: 'KYC',
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
			statusOptions: global.policyStatusPos,
			tableData: [],
			searchParam: {
				clientName: '',
				comRepCode: GET_username(),
				effectiveDate: '',
				page_number: '1',
				page_size: '10',
				policyNumber: '',
				search_code: '',
				status: ''
			}
		};
	},
	computed: {},
	watch: {},
	methods: {
		// 动态设置表格高度
		getHeight() {
			this.tableHeight = window.innerHeight - 270
		},
		// 表格操作-行点击事件
		handleRowClick (row) {
			editPolicy({
				comRepCode: GET_username(),
				id: row.id,
				productLine: row.product_line_code
			}).then(res => {
				// 1.存储submission数据 2.跳转页面
				this.$store.commit('SET_SUBMISSION_DETAIL', res.content)
				this.$router.push('/submission?header=Policy')
			})
		},
		// 获取表格数据
		getTableData(val) {
			this.searchParam.page_number = val ? val : String(this.pages.pageNum)
			this.searchParam.page_size = String(this.pages.pageSize)
			policyList(this.searchParam).then(res => {
				this.pages.total = res.content.count
				this.tableData = res.content.rows
				this.tableData.map(list => {
					let date = list.effectiveDate.split('-')
					list.effectiveDate = date[2] + '/' + date[1] + '/' + date[0]
				})
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		window.addEventListener('resize', this.getHeight)
		this.getHeight()
		this.getTableData()
	},
	destroyed() { window.removeEventListener('resize', this.getHeight) }
}
</script>
<style lang='css' scoped>
.content {
	padding: 15px 60px 10px 60px;
}
.el-form >>> .el-input__inner {
	height: 50px;
}
/* form表单样式 */
.el-form {
	display: flex;
}
.el-form >>> .el-form-item__content .el-date-editor {
	width: 100%;
}
.el-form-item {
	margin-right: 25px;
}
</style>
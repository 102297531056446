<template>
	<div id="app">
		<div class="content">
			<component :is="list[item]"></component>
		</div>
		<div class="tabbar displayFlex flexSpace flexAlignCenter">
			<div @click="checkRouter(0)" :style=" item == 0 ? focusStyle : defaultStyle">
				<i class="el-icon-s-home"></i>
				<p>Home</p>
			</div>
			<div @click="checkRouter(1)" :style=" item == 1 ? focusStyle : defaultStyle">
				<i class="el-icon-s-management"></i>
				<p>Product</p>
			</div>
			<div @click="checkRouter(2)" :style=" item == 2 ? focusStyle : defaultStyle">
				<i class="el-icon-search"></i>
				<p>Search</p>
			</div>
			<div @click="checkRouter(3)" :style=" item == 3 ? focusStyle : defaultStyle">
				<i class="el-icon-s-custom"></i>
				<p>My</p>
			</div>
		</div>
	</div>
</template>

<script>
import Home from './Home'
import Product from './Product'
import Search from './Search'
import My from './My'
export default {
	data() {
		return {
			list: [Home, Product, Search, My],
			item: '0',
			focusStyle: {
				'color': '#F87F18',
			},
			defaultStyle: {
				'color': '#606266',
			}
		};
	},
	methods: {
		checkRouter(item) {
			this.item = item
			this.$store.commit('SET_PAGEITEM', item)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.checkRouter(this.$store.state.User.PageItem)
		if (!this.$store.state.User.Token) {
			this.$router.push('/login')
		}
	},
	beforeCreate() { }, //生命周期 - 创建之前
	beforeMount() { }, //生命周期 - 挂载之前
	beforeUpdate() { }, //生命周期 - 更新之前
	updated() { }, //生命周期 - 更新之后
	beforeDestroy() { }, //生命周期 - 销毁之前
	destroyed() { }, //生命周期 - 销毁完成
	activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
#app {
	display: flex;
	flex-direction: column;
}
.content {
	flex: 1;
	overflow-y: hidden;
	max-height: calc( 100% - 60px );
}
.tabbar {
	height: 60px;
	border-top: 1px solid #8a8989;
}
.tabbar div {
	flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tabbar div i {
	font-size: 36px;
}
</style>
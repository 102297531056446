import axios from 'utils/request.js'
export const plicyView = (data) => {
    return axios.request({
        url: "/api/policyadmin/portal/portal/policy/view",
        method: "post",
        title: "",
        data
    })
}
// YES/NO切换
export const whetherRecord = (data) => {
    return axios.request({
        url: "/api/common/common/remote/whetherRecord",
        method: "post",
        title: "",
        data
    })
}
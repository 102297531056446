<template>
	<div class='h100 displayFlex'>
		<!-- 左侧个人信息 -->
		<div class="w30 displayFlex flexColumn flexAlignCenter">
			<!-- 姓名 -->
			<div class="displayFlex flexColumn flexJCCenter flexAlignCenter h40 w100" style="background: rgb(232, 232, 232);line-height: 40px;">
				<p class="font30 ml10px">{{rep_name}}</p>
				<p style="color: #909399;margin-top: 10px;">{{rep_category}}</p>
			</div>
			<!-- 通知、修改密码、版本 -->
			<div class="w100">
				<el-table class="leftTable" :data="clientList" style="width: 100%" :cell-style="{cursor: 'pointer'}" @row-click="rowClick" :show-header="false">
					<el-table-column prop="name" label="Client Name">
					</el-table-column>
					<el-table-column prop="slot" label="Video Recording" width="80">
						<template slot-scope="scope">
							<el-badge v-if="scope.row.slot === 'badge'" :value="badgeValue"></el-badge>
							<span v-else>{{scope.row.slot}}</span>
						</template>
					</el-table-column>
					<el-table-column label="" width="40" align="right">
						<template>
							<i class="el-icon-right"></i>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 登出按钮 -->
			<div class="w100 h30 displayFlex flexJCCenter flexAlignCenter">
				<el-button type="danger" size="medium" style="width: 90%;display:block;background-color: #F74323;" @click="Logout">Logout</el-button>
			</div>
		</div>
		<!-- 右侧用户列表 -->
		<div class="w70 p30px boxSizing" style="background: white;">
			<p class="mark">Recent</p>
			<!-- 表格 -->
			<el-table :data="tableData" @row-click="rowEdit" style="width: 100%;padding: 20px;" :height="tableHeight" :show-header="false" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="client_name_a" label="Client Name">
				</el-table-column>
				<el-table-column prop="recent_type" label="Recent Type" width="200">
					<template slot-scope="scope">
						<div class="kycStyle">
							{{scope.row.recent_type}}
						</div>
						<div class="statusCell w100px" :style="{'color': scope.row.color, 'background-color': scope.row.backgroundColor, 'border-color': scope.row.borderColor }">
							{{$dictStatus(scope.row.status)}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="date" label="Create Date" width="200">
					<template slot-scope="scope">
						{{scope.row.update_hourTime}}
					</template>
				</el-table-column>
			</el-table>
			<PaginationVue :pages="pages" listKey="getRecentList" pageKey="pages" @SetPagination="$SetPagination">
			</PaginationVue>
		</div>
	</div>
</template>

<script>
import PaginationVue from "../components/Pagination.vue";
import request from '@/utils/request'
import { GET_username } from '../utils/localStorage';
import { getNotificationList, getRecentList } from 'api/My'
import { editKyc, editSubmission } from 'api/KYC'
import moment from 'moment'
export default {
	components: { PaginationVue },
	data () {
		return {
			rep_category: 'Tier',
			rep_name: 'W1w2k e3Jie',
			badgeValue: 0,
			tableHeight: '',
			tableData: [],
			clientList: [{
				name: 'Notifications',
				slot: 'badge'
			}, {
				name: 'My Clients',
				slot: null
			}, {
				name: 'Reset Password',
				slot: null
			}],
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
		};
	},
	computed: {},
	watch: {},
	methods: {
		// 动态获取表格高度
		getHeight () {
			this.tableHeight = window.innerHeight - 180
		},
		// 行点击事件
		rowClick (row, column, event) {
			if (row.name === 'Pending Tasks') {
				this.$router.push('/PendingTasks')
			} else if (row.name === 'My Clients') {
				this.$router.push('/myClient')
			} else if (row.name === 'Notifications') {
				this.$router.push('/Notifications')
			} else if (row.name === 'Reset Password') {
				this.$router.push('/ResetPassword?code=ResetPassword')
			}
		},
		rowEdit (row) {
			if (row.status === '10' && row.recent_type == 'kyc') {
				editKyc({ kyc_no: row.kyc_no }).then(res => {
					// 1.存储kyc数据 2.存储当前页数 3.跳转页面
					this.$store.commit('SET_KYCCLIENT', 0)
					this.$store.commit('SET_KYC_ALL_DATA', { kyc: res.content })
					this.$store.commit('SET_KYCPAGEITEM', 0)
					this.$router.push('/KYC&FNA')
				})
			}
			if (row.status === '1' && row.recent_type == 'submission') {
				editSubmission({
					applicationReferenceNumber: row.application_no,
					productLine: row.product_line_code
				}).then(res => {
					// 1.存储submission数据 2.跳转页面
					this.$store.commit('SET_SUBMISSION_DETAIL', res.content)
					this.$router.push('/submission?header=Submission')
				})
			}
		},
		deleteTest () {
			let url = 'http://172.20.202.198:51001/portal/common/kyc/deleteKyc'
			request({
				url: url,
				method: 'post',
				data: { "kyc_no": "0001" }
			})
		},
		// 登出方法
		Logout () {
			this.$store.dispatch('LogoutByUserName', { infinitum_rep_code: GET_username() })
		},
		// 获取提示条数
		async getCount () {
			getNotificationList({
				page_number: '1',
				page_size: '999',
				recipient: GET_username(),
			}).then(res => {
				this.badgeValue = 0
				if (res.content.notification_list) {
					res.content.notification_list.map(n => {
						if (n.is_read === '1') this.badgeValue += 1
					})
				}
			})
		},
		// 获取recent条数
		getRecentList () {
			getRecentList({
				page_number: String(this.pages.pageNum),
				page_size: String(this.pages.pageSize),
				agent_code: GET_username()
			}).then(res => {
				if (res.content) {
					this.pages.total = res.content.count
					this.tableData = res.content.recent_list
					this.tableData.map(data => {
						if (data.status && data.status !== '15') {
							data.color = global.config[data.status].statusColor
							data.backgroundColor = global.config[data.status].statusBgColor
							data.borderColor = global.config[data.status].statusColor
						}
						const updateTime = this.getTimeDifference(data.update_time)
						if (updateTime === 0) {
							data.update_hourTime = "within an hour"
						} else if (updateTime < 24) {
							data.update_hourTime = updateTime + " " + "hour ago"
						} else if (updateTime >= 24 && updateTime < 48) {
							data.update_hourTime = "yesterday"
						} else if (updateTime >= 48) {
							data.update_hourTime = moment(data.update_time).format('MM/DD/YYYY')
        				}
					})
				}
			})
		},
		// Calculate the difference in hours
		getTimeDifference(d1) {
			const dateBegin = new Date(d1.replace(/-/g, "/")); //Convert-to /, use new Date
			const dateEnd = new Date(); // Get current time
			const dateDiff = dateEnd.getTime() - dateBegin.getTime(); // Time difference in milliseconds
			const hours = Math.floor(dateDiff / (3600 * 1000)); // Calculate the difference in hours
			return hours;
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		if (this.$store.state.User.PageItem === 3) {
			window.addEventListener('resize', this.getHeight)
			// 设置rep名称和类别
			this.rep_category = this.$store.state.User.Rep.rep_category
			this.rep_name = this.$store.state.User.Rep.rep_name
			this.getHeight()
			this.getCount()
			this.getRecentList()
			if (this.rep_category !== 'Tier1') this.clientList.unshift({
				name: 'Pending Tasks',
				slot: null
			})
		}
	},
	destroyed () { window.removeEventListener('resize', this.getHeight) },
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	}
}
</script>
<style lang='css' scoped>
.mark {
	font-size: 30px;
	color: rgb(56, 56, 56);
}
.el-badge >>> .el-badge__content {
	background-color: #f74323;
}
/* 增加表格行高 */
.el-table .leftTable >>> tr {
	height: 70px;
}
.el-button--medium {
	padding: 17px 20px;
	font-size: 18px;
}
.kycStyle {
	display: inline-block;
	color: #ff9941;
	border: 1px solid #ff9941;
	text-align: center;
	width: 40px;
}
.statusCell {
	margin-left: 10px;
	display: inline-block;
	border: 1px solid rgb(12, 12, 12);
	text-align: center;
}
</style>
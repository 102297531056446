import axios from 'utils/request.js'
export const getVideoupload = (data) => {
    return axios.request({
        url: "/api/common/portal/common/videoupload/getVideoupload",
        method: "post",
        title: "",
        data
    })
}
export const getToDoList = (data) => {
    return axios.request({
        url: "/api/common/portal/common/income/getToDoList",
        method: "post",
        title: "",
        data
    })
}

export const selectAgentInfo = (data) => {
    return axios.request({
        url: "/api/dms/portal/advisorPortalInterface/selectAgentInfo",
        method: "post",
        title: "",
        data
    })
}
// MyInfo二维码
export const qrCodeImage = (data) => {
    return axios.request({
        url: "/api/crm/portal/portalClient/qrCode",
        method: "post",
        title: "",
        data
    })
}

// MyInfo获取数据
export const getPersonData = (data) => {
    return axios.request({
        url: "/api/crm/portal/portalClient/getPersonData",
        method: "post",
        title: "",
        data
    })
}